/* goals */
.goals_card ul {
    display: flex;
    gap: 10px;
}

.input_wrap_border {
    border: 1px solid red;
}

.goals_card li {
    border-bottom: 3px solid #f978c8;
    background: #fff;
    text-align: center;
    padding: 10px;
    border-radius: 7px;
    width: 100%;
}

.goals_card li:nth-child(2) {
    border-color: #00eacb;
}

.goals_card li:nth-child(3) {
    border-color: #4759f7;
}

.goals_card li:nth-child(4) {
    border-color: #7209b7;
}



div *::-webkit-scrollbar {
    height: 10px;
    width: 10px;
  }
div  *::-webkit-scrollbar-track {
    border-radius: 5px;
    background: #E7E7E7;
  }
  
div *::-webkit-scrollbar-track:hover {
    background: #E7E7E7;
  }
  
div *::-webkit-scrollbar-track:active {
    background: #E7E7E7;
  }
  
div *::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background-color: darkgrey;
}
  
div *::-webkit-scrollbar-thumb:hover {
    background-color: darkgrey;
}
  
div *::-webkit-scrollbar-thumb:active {
    background-color: darkgrey;
}


.nodata {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 200px;
}

.reportOverflow .search-wrapper {
    overflow: auto !important;
}

.metricsFlex {
    display: flex;
    justify-content: space-between;
}

.multiSelectContainer {
    text-align: left;
    width: 100%;
}

.btnMetrics {
    width: 100px;
    height: 40px;
    flex-grow: 0;
    border-radius: 100px;
    background-color: #4759f7;
    border: transparent;
    color: #fff;
    font-weight: 700;
}

.no_data_found img {
    width: 314px;
    margin: auto;
}

select#formBasicEmail:focus {
    box-shadow: unset;
}

.no_data_found {
    padding: 50px 20px;
    min-height: 350px;
    align-items: center;
}

.goals_card li:nth-child(6) {
    border-color: #00eacb;
}

.no_data_found img {
    width: 314px;
    margin: auto;
}

.no_data_found {
    padding: 50px 20px;
}

.goals_card p {
    margin-bottom: 6px;
}

.goals_card h4 {
    font-weight: 700;
}

/* goals */
.metrics ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.metrics>ul>li {
    flex: unset !important;
    margin-bottom: 15px;
}

.input_wrap {
    background: #fff;
    border-radius: 6px;
    position: relative;
}

.input_wrap_multiSelect {
    background: #fff;
    border-radius: 6px;
    position: relative;
}

.input_wrap img.custom_chevron_down {
    position: absolute;
    right: 8px;
    top: 14px;
}

.resetBtns.report_actions .btn-outline-success {
    width: 220px;
    height: 40px;
    flex-grow: 0;
    padding: 11px 73.9px 12px 75.4px;
    border-radius: 100px;
    background-color: #4759f7;
    border: transparent;
    color: #fff;
    font-weight: 700;
}

.content .metrics h2 {
    font-size: 24px;
    font-weight: 700;
    color: #000;
}

.resetBtns.report_actions {
    border-top: 1px solid #ddd;
    margin-top: 10px;
    text-align: right
}

.reportTypeTab {
    display: flex;
    justify-content: space-around;
    align-items: center;
    font-family: "Montserrat" !important;
    font-size: 12px;
    font-weight: bold;
    height: 40px;
}

.buttonReportTypeTab {
    all: unset;
}

.dropdownLineItemPlacement {
    font-size: 12px;
    height: 30px;
    font-weight: 500;
    background-color: #FFFFFF;
    box-shadow: 0px 10px 60px rgba(226, 236, 249, 0.5);
    border-radius: 35px;
    border: transparent;
}

.divDropdownPlacements {
    margin-top: 35px;
    margin-left: 20px;
}

.divDropdownPlacementsPaddingBottom {
    padding-bottom: 100px;
}

.divDropdownPlacementsNotFound {
    padding-top: -220px;
}

.dropdownPlacements {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 49%;
    height: 45px;
    border-radius: 13px;
    background-color: white;
    padding: 12px 15px;
    color: #000;
    font-weight: 400;
    font-size: 12px;
    border: transparent;
}

.dropdownPlacementsMenu {
    margin-top: 10px;
}

.showPlacementDescription {
    margin: 45px 30px;
    font-family: "Montserrat" !important;
    font-size: 15px;
    font-weight: 400;
}

.dropdown-toggle::after {
    display: none;
  }

.colLineItemTitleListBorderBottom {
    border-bottom: 4px solid #3948CA;
}

.resetBtns.report_actions button.btn.btn-outline-danger ,.modal-body .cancel_cm_btn{
    background: transparent;
    border: transparent;
    color: #4759f7;
    font-weight: bold;
}

.status_dropdown.show.dropdown .dropdown-menu {
    inset: unset !important;
    transform: unset !important;
    bottom: -14px !important;
    z-index: 1;
}

.card.report__table td {
    padding: 10px;
}

.card.report__table th {
    background: #ddc6eb;
    color: #242833;
    font-weight: 700;
    font-size: 12px;
    padding: 10px;
}

.report__table button.expo_button {
    font-size: 12px;
    line-height: 1;
    border: 1px solid #28a745;
    background: #28a745;
    padding: 0.5rem 1.5rem;
    border-radius: 0.25rem;

    color: #fff;
}

button.expo_button {
    background: transparent;
    font-size: 12px;
    line-height: 1;
    border: 1px solid #28a745;
    color: #28a745;
    padding: 0.5rem 1.5rem;
    border-radius: 0.25rem;
}

.card {
    margin-bottom: 1.875em;
    border-radius: 5px;
    padding: 0;
    border: 0px solid transparent;
    -webkit-box-shadow: 0 0 20px rgb(0 0 0 / 8%);
    box-shadow: 0 0 20px rgb(0 0 0 / 8%);
}

table.table {
    margin-bottom: 0;
}

table .table>thead>tr>th {
    font-weight: 600;
    font-size: 12px;
    color: #000;
    text-transform: capitalize;
}

table.table .text-truncate {
    max-width: 25rem;
}

/* media query starts here */
@media(max-width:991px) {}

@media(max-width:767px) {
    .resetBtns label {
        display: none;
    }
}


/* Advance Reports (Advance, Audicence, Performance, Saved) */

.divSuperiorAdvanceReports {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	padding-bottom: 30px;
}

.divContentAdvanceReports {
	width: 100%;
	display: flex;
	justify-content: center;
	align-content: center;
	padding-top: 20px;
	margin-bottom: 30px;
}

.divAdvanceReportsTitles {
	width: 100%;
	height: 40px;
	cursor: pointer;
}

.divAdvanceReportsTitlesBottom {
	padding-bottom: 6px;
	border-bottom: 1px solid #ddd;
}

.divContentPerformanceReport {
	/*display: flex;
	justify-content: center;
	align-content: center;*/
	width: 100%;
	padding-bottom: 30px;
}

.divButtonsPerformanceReports {
	display: flex;
	justify-content: flex-end;
}

.divBoxPerformanceReport {
	width: 100%;
	background-color: white;
	padding-bottom: 30px;
}

.divSearcherPerformance {
	display: flex;
	gap: 20px;
}

.lettersPerformanceReport {
	color: #242833 !important;
	font-family: 'Montserrat' !important;
	font-size: 14px !important;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.43;
	letter-spacing: normal;
	text-align: left;
}

.fontBold {
	font-weight: bold;
}

.reportInputName {
	height: 48px;
	width: 100% !important;
	border-radius: 9px;
	border: none !important;
	padding: 10px;
	border: transparent;
}

.reportInputName:focus,  .reportsInputDates:focus {
	outline: none !important;
}

.reportsInputDates {
	height: 48px;
	width: 100% !important;
	border-radius: 9px;
	border: none;
	padding: 10px;
}

.divSectionPerformanceReport {
	padding-top: 30px !important;
	padding-left: 30px !important;
	padding-right: 30px !important;
}

.divFiltersPerformanceReport {
	height: 48px;
	border-radius: 9px;
	padding: 10px;
	display: flex;
	flex-wrap: wrap;
	align-content: center;
}

.pFilters {
	margin-top: 10px;
    font-weight: 500;
}

.divButtonFiltersTitle {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	padding: 6px 16px;
}

.divButtonFiltersCancel {
	display: flex;
	justify-content: flex-end
}

.buttonFilters {
  height: 29px;
  margin-top: 5px;
	margin-left: 10px;
}

.divFilterList {
  display: inline-flex;
  flex-direction: row;
  position: relative;
}

.buttonAdd {
	background-color: #4759f7;
	width: 85px;
	gap: 4px;
	position: relative;
	height: 32px;
}

.buttonFiltersVariables {
  width: 187px;
  padding: 6px 16px;
  border-radius: 100px;
  border: solid 2px #4759f7;
  color: #4759f7;
  font-weight: 700;
  display: flex;
  align-items: center;
}

.buttonFiltersVariablesSmaller {
	width: 130px;
  padding: 6px 16px;
  border-radius: 100px;
  border: solid 2px #4759f7;
  color: #4759f7;
  font-weight: 700;
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
  background-color: white;
}

.divFiltersListPerformanceReport {
	background-color: white;
	height: auto;
	width: 220px;
  position: absolute;
  z-index: 2;
	overflow-x: hidden;
	overflow-y: auto;
	border-radius: 9px;
	margin-left: 55px;
	box-shadow: 5px 5px 15px gray;
  top: 38px;
}

.divSearcherFilters {
	padding: 10px;
}

.cursorPointer {
	cursor: pointer;
}

.divItemsPerformanceReport {
	height: 508px;
	width: 100%;
	border-radius: 9px;
}

.divTablePerformanceReportOverflow {
	background-color: white !important;
	overflow: auto;
	position: relative;
}

.tablePerformanceReport thead {
  position: sticky;
  top: 0;
	background-color: white;
  z-index: 1;
}

.tablePerformanceReport{
	width: 100%;
  border-collapse: separate;
	border-spacing: 0;
	border-left: 2px solid #f4f2f9;
}

.tablePerformanceReport tbody{
	min-width: 400px;
	width: 100%;
}

.tableMyReports {
  width: 100%;
  background-color: white !important;
  border-top-left-radius: 8px !important;
  border-top-right-radius: 8px !important;
}

.thPerformanceReport, .thMyReports {
  text-align: center;
  padding: 8px;
	min-width: 100px;
	border-top: 2px solid #f4f2f9;
}

.thMyReportsRadiusTopRight {
  border-top-right-radius: 8px !important;
}

.thMyReportsRadiusTopLeft {
  border-top-left-radius: 8px !important;
}

.thSmallMyReports {
	width: 5px !important;
}

.divThPerformanceReport {
  display: flex;
  justify-content: center;
  align-items: center;
}

.thPerformanceReportLarge {
	min-width: 220px !important;
}

.tdSmallMyReports {
  width: 5px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  height: inherit;
}

.tdPerformanceReport, .tdMyReports {
  text-align: center;
  padding: 8px;
  min-width: 100px;
}

.tdPerformanceReportRight {
  text-align: right !important;
}

.thPerformanceReport, .tdPerformanceReport {
  border-bottom: 2px solid #f4f2f9;
  border-right: 2px solid #f4f2f9;
}

.thPerformanceReport, .tdMyReports {
  border-bottom: 2px solid #f4f2f9;
}

.thPerformanceReport:last-child,
.tdPerformanceReport:last-child,
.tdMyReports:last-child {
  border-right: none;
}

.tdPerformanceReportMid {
  min-width: 150px !important;
}

.tdPerformanceReportLarge {
  min-width: 300px !important;
}

.tdPerformanceReportMediumLarge {
  min-width: 190px !important;
}

.trPerformanceReport:nth-child(even) {
  background-color: #f4f2f9;
}

.thPerformanceReportHeader {
  background-color: white;
}

.trMyReports:nth-child(odd) {
  background-color: #f4f2f9 !important;
}

.trMyReports {
  height: 39px;
}

.rowItemsPerformanceReport {
	padding: 16px 12px;
}

@media(max-width:1267px) {
	.rowItemsPerformanceReport {
		overflow-y: scroll;
	}

	.listColRowReports {
		max-height: 150px !important;
	}

	.divSelectedAttributesPerformanceReport {
		max-height: 150px;
	}
}

@media(min-width:1408px) {
	.listColRowReports {
		height: 305px !important;
	}

  .listColRowReportsWithClearButton {
    height: 285px !important;
  }
}

.tabsColumnRow {
	padding: 20px;
	cursor: pointer;
}

.colColumnRows {
	display: flex;
	justify-content: center;
	align-items: center;
}

.colReportBorderBottom {
	border-bottom: 4px solid #3948CA;
}

.colReportFont {
	font-weight: 800 !important;
}

.listColRowReports {
	border-radius: 9px;
	height: 285px;
	width: 100%;
	padding-left: 10px;
	padding-top: 10px;
	margin-top: 20px;
	overflow-x: hidden;
	overflow-y: auto;
	text-align: justify;
  display: flex;
  flex-direction: column;
}

.listColRowReportsWithClearButton {
	height: 265px;
	padding-top: 5px !important;
	margin-top: 5px !important;
}

.btnClearRowsColumns {
	margin-top: 15px;
	padding-left: 10px;
	background: none;
	border: none;
	color: #4759f7;
	font-size: 12px;
	font-weight: bold;
	font-family: "Montserrat" !important;
}

.divContentSelectedAttributesPerformanceReport {
	display: flex;
	flex-wrap: wrap;
	align-content: center;
	align-content: space-between;
}

.divSelectedAttributesPerformanceReport {
	background-color: white;
	border-radius: 9px;
	height: 206px;
	width: 100%;
	padding-left: 12px;
	padding-top: 12px;
  padding-right: 12px;
}

.divSelectedAttributesPerformanceReport + .divSelectedAttributesPerformanceReport {
    margin-top: 10px;
}

.fixedTitleSelectedAttributes {
  top: 0;
  position: relative;
}

.scrollSelectedAttributes {
  overflow: auto;
  height: 167px;
}

.divIndividualSelectedAttributesPerformanceReport {
	background-color: inherit;
	border: 2px solid #4759f7;
  display: flex;
  padding: 5px 15px;
  justify-content: space-between;
  align-items: center;
  border-radius: 15px;
	margin-bottom: 11px;
  width: 220px;
  color: #4759f7 !important;
  width: 95%;
}

.divIndividualParagraphSelectedAttributesPerformanceReport {
	font-weight: 600;
	color: #4759f7;
	margin-top: 0px;
	margin-bottom: 0px;
}

.divModalFilterReportsTitle {
	display: flex;
	justify-content: center;
	width: 70%;
}

.divModalFilterReportsTitleSearcher {
	width: 60%;
}

.rowModalFilter {
	padding: 20px;
}

.colModalFilter {
	height: 400px;
	overflow-x: hidden;
	overflow-y: auto;
}

.divListAttribute {
	display: flex;
	gap: 15px;
	overflow-x: hidden;
	overflow-y: auto;
	padding-bottom: 10px;
}

.modalTitle {
	font-size: 15px;
	width: 150px;
}

.divRowsColumnsPerformanceReport {
	display: flex;
}

.divCollapseRowColPerformanceReport {
	width: 45%;
}

.contentHalfResultsPerformanceReport {
	display: flex;
	justify-content: flex-end;
	width: 55%;
}

.contentFullResultsPerformanceReport {
	display: flex;
	justify-content: center;
	width: 100%;
}

.divResultsRowColPerformanceReport {
	width: 99%;
}

.collapsableButton {
	height: 40px;
	width: 40px;
	border-radius: 2px;
	border: none;
	border-radius: 0 8px 8px 0;
	margin-top: 190px;
}

.divDateRange {
  display: flex;
	justify-content: center;
}

.divDateRangeButton {
	display: flex;
	justify-content: flex-end;
	align-items: center;
	padding: 30px 24px;
}

.h3Cancel {
	color: #4759f7;
	font-family: 'Montserrat' !important;
	cursor: pointer;
	font-size: 12px !important;
	font-weight: 800;
	padding-top: 5px;
	padding-right: 60px;
}

.reportIcons {
  width: 35px;
	cursor: pointer;
}

.cursorWait {
	cursor: wait !important;
}

.coldivIconsReports {
	display: flex;
	justify-content: flex-end;
}

.divIconsReports {
	width: 100%;
	display: flex;
	justify-content: space-around;
}

.modalFilterSelectAll {
  padding-left: 20px;
}

.loaderSpinner {
  color: #4759f7;
	width: 80px;
	height: 74px;
	padding: 40px;

}

.divWithoutResultsContainer .marginWithoutResults {
  margin-top: 190px;
}

.divSpinnerFlex {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 150px;
}

.divWithoutResultsContainer {
  display: flex;
  justify-content: center;
  align-items: flex-start;
	width: 55%;
}

.divWithoutResults {
	width: 600px;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	align-items: center;
	align-content: center;
  margin-top: 190px;
}

.rowButton {
  background: none;
	color: inherit;
	border: none;
	padding: 0;
	font: inherit;
	cursor: pointer;
	outline: inherit;
  width: auto;
  text-align: left;
  margin-bottom: 12px;
}

.rowButton.disabled {
  color: #aaa;
  cursor: not-allowed;
}

.backgroundColorAdvanceReports {
	background-color: #f4f2f9 !important;
}

.modalFiltersselectedItems {
	padding-bottom: 5px;
}

.divButtonExecute {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 40px;
}

.divMarginTopAdvanceReports {
  margin-top: 12px;
}

.divMyReports {
  width: 100%;
  min-height: 294px;
  box-sizing: content-box;
  padding: 30px;
}

.divNewReportMyReports {
  width: 100%;
  margin-bottom: 16px;
  display: flex;
  align-content: center;
  justify-content: space-between;
}

.btnDeleteMyReports {
  background-color: transparent;
  border-color: #4759f7;
  color: #4759f7;
  border-radius: 100px;
  flex-grow: 0;
  font-weight: 700;
  border-style: solid;
}

.btnDeleteMyReports:hover {
  color: #fff;
  background-color: #4759f7;
}

.btnDeleteMyReportsDisable {
  background-color: transparent;
  border-color: #989fb3;
  color: #989fb3;
  border-radius: 100px;
  flex-grow: 0;
  font-weight: 700;
  border-style: solid;
  cursor: default !important;
}

.aViewMyReports {
  color: #4759f7;
  font-family: 'Montserrat';
  font-size: 12px;
  font-weight: bold;
}

.tablePagination {
  width: 100%;
  height: 44px;
  display: flex;
  justify-content: flex-end;
  padding: 12px 16px;
  font-family: 'Montserrat';
  font-size: 12px;
  font-weight: normal;
  box-shadow: 0 0 14px 0 #e9ebf1;
  background-color: #fff;
  color: #242833;
  font-weight: 500;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

.ulPagination {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-direction: row;
  width: 100%;
  height: 100%;
  gap: 20px;
}

.spanPagination {
  color: #585f74;
}

.selectPaginationValues {
  width: 55px;
  height: 32px;
  padding-left: 12px;
  flex-grow: 0;
  border-radius: 32px;
  background-color: #f6f2f9;
  border: none !important;
}

.selectPaginationValues:focus {
  outline: none !important;
}

.divSpinnerMyReports {
  width: 100%;
  height: 250px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.backgroundWhite {
  background-color: white !important;
}

.trMyReportsHead {
  height: 39px;
}

.divInfoIcon {
  padding-left: 10px;
  cursor: pointer;
}

.divExclamationIcon {
  padding-left: 5px;
  cursor: pointer;
}

.divTitlePerformanceReportWelcome {
  background-color: #d3e1f8;
  height: 30px;
  width: 100%;
  display: flex;
  align-items: flex-end;
  padding-left: 10px;
}

.cursorAuto {
  cursor: auto !important;
}