
.profile_image_wrapper, .profile_image_wrapper .user_image {
    width: 120px;
    height: 120px;
    position: relative;
    border-radius: 50%;
    object-fit: cover;
}

.profile_image_wrapper img {
    border-radius: 50%;
}
.modal-body {
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
}
img.edit_profile_img {
    position: absolute;
    bottom: -15px;
    right: -15px;
}
.profile_hader {
    display: flex;
    align-items: center;
    list-style: ce;
    gap: 1.5em;
    justify-content: center;
    margin-bottom: 40px;
    margin-top: 15px;
}

.content .profile_hader h2 {
    font-size: 24px;
    font-weight: 400;
}
.drop_image, .drop_image img {
    width: 200px;
    height: 200px;
    margin: auto;
    border-radius: 50%;
    object-fit: cover;
    margin-bottom: 30px;
}

.add_profile h2 {
    margin-bottom: 21px;
}
.drop_image, .profile_image_wrapper {
    background: orange;
    display: flex;
    align-items: center;
    justify-content: center;
}

.drop_image p, .profile_image_wrapper p {
    margin: 0px;
    font-size: 6em;
    color: #fff;
}

.border-red {
    border: 1px solid red;
}
.profile_image_wrapper p {
font-size: 4em;
}
.bordered_btn {
    border: 2px solid #4759F7;
    border-radius: 35px;
    color: #4759F7;
    font-weight: 700;
    min-height: 40px;
    min-width: 160px;
    background: transparent;
    cursor: pointer;
}

.upload_profile_image input {
    min-height: 40px;
    min-width: 160px;
    position: absolute;
    opacity: 0;
    width: 100%;
}

.upload_profile_image {
    position: relative;
    text-align: center;
}