.card_outer.statistics {
    min-height: 377px;
}

.table>:not(:first-child) {
    border-top: unset;
}

.table>thead>tr>th {
    font-weight: 300;
    font-size: 16px;
}

.table>tbody>tr>td {
    font-size: 11px;
}

.table>thead {
    vertical-align: bottom;
    background: #e8e9ef;
    color: #868e96;

}
.white_space {
    white-space: wrap;
    /* min-height: 48px !important; */
    left: 3px;
    width: 98%;
}

.multiselectContainer {
    border-radius: 0px !important;
}

.searchBox {
    border-radius: 0px !important;

}

.searchBox {
	color: #585f74;
    font-weight: 500;
}

.chip {
    margin-top: 5px;
}

.multiSelect_form {
    height: 50px;
}

.pie_chart {
    padding: 10px;
    background: #f2f9ff;
}

table.table {
    border: 1px solid #ddd;
}

.high_chart div {
    height: 400px;
}

.pie_chart>div {
    height: 390px;
    background-color: #fff;
}

.home_filter input:focus,
.home_filter select:focus {
    box-shadow: unset;
    outline: unset;
}

.home_filter input:focus,
.home_filter select:focus {
    box-shadow: unset;
    outline: unset;
}

.plain_select .form-select {
    border-radius: 6px;
}
p.view_text.vtr{
 color: #485af7;   
}
.view_card_saas.vtr {
    background-color: #485af7;
}

form.home_filter .select_country_container {
    margin-top: 0px;
}

form.home_filter .form-control:disabled {
    border-radius: 6px;
}

button.transparent {
    background: transparent;
    border: transparent;
    color: #989fb3;
    padding: 12px;
    font-weight: 600;
    min-width: 100px;
}

.transparentMinWidth {
    min-width: 200px !important;
}

button.transparent:hover {
    background-color: unset;
    color: #989fb3;
}

button.grey_btn:hover {
    background-color: #989fb3;
    color: #fff;
}

button.grey_btn {
    background: #989fb3;
    border: #989fb3;
    color: #fff;
    border-radius: 100px;
    padding: 11px;
    font-weight: 600;
}

.home_filter .card_outer p {
    font-size: 12px;
    font-weight: 600;
    text-align: center;
    margin-bottom: 0;
}

.home_filter .card_outer h4 {
    text-align: center;
    font-size: 16px !important;
}

.home_filter .card_outer p.imp_text {
    color: #4759f7;
    margin-bottom: 20px;
}

p.view_text {
    color: #00d7c8;
}

p.click_text {
    color: #f978c8;
}

p.engage_text {
    color: #7209b7;
}

.view_card {
    border-radius: 8px;
    box-shadow: 0 0 14px 0 #e9ebf1;
    background-color: #00d7c8;
    padding: 24px 10px;
    text-align: center;
}

.view_card_saas {
    border-radius: 8px;
    box-shadow: 0 0 14px 0 #e9ebf1;
    background-color: #00d7c8;
    padding: 10px 10px;
    text-align: center;
}

.view_card_saas h4 {
    margin: 0;
    font-weight: 600;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    margin-bottom: 0;
    padding-bottom: 5px;
}

.view_card h4 {
    margin: 0;
    font-weight: 600;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    margin-bottom: 0;
}

.card_outer img.img-fluid {
    height: 100px;
}

h4.user__name {
    font-size: 24px;
    margin-bottom: 24px;
    font-weight: 400;
    margin-top: 15px;
}

.view_card h4 span, .view_card_saas h4 span {
    font-weight: 500;
    font-size: 12px;
}

.view_card.ctr_bg, .view_card_saas.ctr_bg {
    background: #f978c8;
}

.view_card.er_bg, .view_card_saas.er_bg {
    background: #7209b7;
}

.content .home_title {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
}

.content h2.home_subtitle {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 24px;
    margin-top: 10px;
}

.home_filter tr {
    border-color: #ddd;
}

.home_filter .advert_tabel thead  th {
    text-align: left;
}

.select_country_container_multiSelect {
    height: 48px;
    background-color: #f6f7f9;
    /* border-radius: unset; */
    box-shadow: unset;
}

/* style={{ paddingTop: "35px", paddingBottom: "13px" }} */

/* media query starts here */
@media(max-width:991px) {

    .submit_btn label,
    .submit_btn br {
        display: none;
    }
}