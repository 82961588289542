html {
	background: #fafafa;
	font-size: 12px;
	width: 100%;
	height: 100%;
}

.App {
	font-family: "Roboto", sans-serif;
	-webkit-font-smoothing: antialiased;
	font-weight: 400;
	line-height: 1.42857;
	text-rendering: optimizeLegibility;
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	position: relative;
}

.addUserBtn {
	margin-top: -12px;
	border-radius: 57px;
	width: 3%;
}
a.btn.btn-outline-success.add_user {
	position: absolute;
	right: 10px;
	top: 10px;
	z-index: 1;
}
.rmsc.multi-select .dropdown-heading {
	height: 27px;
	color: #000;
}

.rmsc.multi-select .dropdown-heading span {
	display: flex;
	color: #000;
}

.rmsc.multi-select .dropdown-heading svg {
	color: #000;
}
svg.icons_font {
	font-size: 12px;
	margin: 0px 5px;
}
.sass_checkbox.access_login input[type="checkbox"]:checked {
    background: #6750a4;
}
.sass_checkbox.access_login input[type="checkbox"]:checked::before {
    transform: translateX(12px);
    background: #ffffff;
}
.sass_checkbox.access_login input[type="checkbox"]::before{
	top: 5px;
}
.user_header {
    background: #fff;
    padding: 20px 15px;
}

.user_header h2 {
    font-size: 16px;
    font-weight: bold;
}

.add_user_form .form-group.select_country_container.cmn_modal_field input, 
.add_user_form .form-group.select_country_container.cmn_modal_field select,
.add_user_form .form-group.select_country_container.cmn_modal_field{
  background-color: #fff;
}
.form_inner.add_user_form,.user_header h2 
	{
    max-width: 80%;
    margin: auto;
}
.select_country_container .multiSelectContainer input{
margin-top: 0;
}
.select_country_container label.lbl_position.multisel_lbl{
z-index: 1;
color: #585f74;
}
.select_country_container .form-select{
	color: #585f74;
}
div.bg_transparent{
	background: transparent;
}
.btns_bg button.cmn_btn{
	background: #4759f7;
    padding: 11px 40px;
    border-radius: 35px;
    height: unset;
    box-shadow: unset;
    font-size: 12px;
    font-weight: bold;
}
/* .subgroup_filter .dropdown-menu.show {
    top: inherit !important;
} */
.subgroup_filter div.permission_btn {
    width: 300px;
    flex-grow: unset;
    display: flex;
    align-items: center;
    justify-content: center;
}

.permission_btn .grey_btn {
    width: 100%;
}

.subgroup_filter {
    display: flex;
    /* align-items: center; */
    justify-content: center;
    gap: 15px;
}
.subgroup_filter button#dropdown-basic{
	justify-content: space-between;
}
.show.dropdown .search_permission .form-control  {
    box-shadow: unset;
     padding-left:0px;
}

.btns_bg button.cmn_btn.grey_btn{
	background:#989fb3 ;
}
.subgroup_filter .owner_filter.dropdown {
    height: unset;
}
.subgroup_filter> div {
    flex-grow: 1;
    width: 40.2%;
}
.subgroup_tabs .form-control {
    height: 30px;
}
.subgroup_tabs .campiagn_filter #dropdown-basic {
    height: 32px;
}
.subgroup_filter.cmn__filters.campiagn_filter.filter_menu .dropdown-menu.show{
    position:absolute !important;
}
.subgroup_filter.cmn__filters.campiagn_filter.filter_menu .dropdown-menu.show{
    position:absolute !important;
}

.subgroup_filter div.optionListContainer {
    width: 100%;
}
.cmn__filters ul.optionContainer li{
	width: 100%;
	padding: 10px;
}
.cmn__filters ul.optionContainer {
    display: block;
   
	margin: 0;
}
.subgroup_tabs .nav-tabs .nav-link.active {
    background: #485af7;
    color: #fff;
}

.subgroup_tabs .nav-tabs .nav-link {
    border-radius: 35px;
    min-width: 210px;
    height: 40px;
    background: #989fb3;
    color: #fff;
    font-weight: bold;
}

.subgroup_tabs  ul.nav.nav-tabs {
    gap: 10px;
    border: unset;
}
@media(max-width:1399px){
	.form_inner.add_user_form,.user_header h2 
	{
    max-width: 90%;
}
}