.cmn_border {
    border: 1px solid #000;
    padding: 15px;
    border-radius: 10px;
    background-color: #f8f5f5;
}

.campiagn_outer .cmn_btn {
    margin-left: auto;
    height: 40px;
}
.content_outer.adminSaas .cmn_header_top.campaign__header {
    padding: 24px 16px;
}
.alert_icon{
    stroke: orange;
}

.applyCenter {
    display: flex;
    justify-content: center;
    align-content: center;
}

.marginApply {
    margin: 20px !important;
}

button.aplly_btn {
    background: #4759f7;
    padding: 11px 40px;
    border-radius: 35px;
    height: unset;
    box-shadow: unset;
    font-size: 13px !important;
    font-weight: bold;
    height: 40px !important;
    border: unset;
    min-width: 300px;
    color: #fff;
}

.divApplyChanges {
    display: flex;
}

.divCancelChanges {
    display: flex;
}

.search_dropown {
    position: absolute;
    top: 50px;
    background: #fff;
    width: 100%;
    left: 0;
    box-shadow: 0px 0px 3px rgba(0,0,0,9%);
    border-radius: 6px;
}
.search_dropown ul::-webkit-scrollbar {
    width: 4px;
  }
   
  .search_dropown ul::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }
   
  .search_dropown ul::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    outline: 1px solid slategrey;
  }
.search_dropown ul {
    margin: 0;
    max-height: 233px;
    overflow: auto;
}

.search_dropown ul li {
    padding: 10px;
    border-bottom: 1px solid #ddd;
    text-align: left;
    font-weight: 600;
    text-transform: capitalize;
}

.search_dropown ul li:last-child {
    border-bottom: unset;
}
button.cmn_search_btn {
    border: transparent;
    background: #ddc6eb;
    min-width: 95px;
    border-radius: 35px;
    font-weight: bold;
    height: 40px;
    color: #000;
}
.multiselect_bottom ul.optionContainer {
    max-height: 110px;
}
span.green_dot {
    display: inline-block;
    width: 13px;
    height: 13px;
    background: #14FF00;
    border-radius: 50%;
}
label.lbl_position p {
    font-size: 9px;
}
label.lable_title {
    font-size: 16px;
}
.Billable_btn {
    color: gray !important;
    border: 1px solid gray !important;
}



button.edt_table {
    padding: 7px 11px;
    background: #FFFFFF;
    border: 1px solid #EEEEEE;
    border-radius: 7px;
}

button.clear__btn {
    border: transparent;
    background: #989fb3;
    min-width: 95px;
    border-radius: 35px;
    font-weight: bold;
    height: 40px;
    color: #fff;
}

button.clear__btn1 {
    border: transparent;
    background: #4759f7;
    min-width: 95px;
    border-radius: 35px;
    font-weight: bold;
    height: 40px;
    color: #fff;
}

.File_input {
    width: 250px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.media_header button.cmn_btn {
    height: 40px;
    text-align: center;
}

.export_table button.button.expo_button {
    position: absolute;
    right: 5px;
    top: 23px;
    background: #151b3e;
    color: #fff;
    border: transparent;
    border-radius: 7px;
    font-size: 14px;
}

.filter_bg {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    background: #fff;
    flex-grow: 1;
    margin-right: 11px;
    padding-right: 10px;
    padding: 4px;
    border-radius: 12px;
}

.campiagn_filter {
    display: flex;
    align-items: center;
    padding-top: 0px;
}

.campiagn_filter button {
    height: 40px;
}

.capiagn_filter {
    background: #fff;
    padding: 15px;
    display: flex;

}

.modal-content {
    border-radius: 20px !important;
    /* overflow: hidden; */
}

.filter_title {
    margin-bottom: 24px;
    font-weight: bold;
    color: #000;
    font-size: 16px;
}
/* 
.react-datepicker-popper {
    z-index: 9999 !important;
} */

.media_dropowns.user_menus .dropdown-menu.show {
    transform: unset !important;
    inset: unset !important;
    right: 10px !important;
}

.cmn_btn {
    border: transparent;
    background: #10163A;
    border-radius: 12px;
    padding: 10px 15px;
    font-size: 16px;
    height: 40px;
    color: #fff;
    min-width: 80px;
    text-align: center;
    min-width: 120px;
}

span.text_ellips {
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    white-space: pre-wrap;
    margin-top: 5px;
}

.modal-body label {
    margin-bottom: 10px;
}

.newcampaign a {
    display: inline-block;
    color: #000;
}

.add_filter {
    position: relative;
}

.add_filter p {
    cursor: pointer;
}

.filter_menu ul {
    margin: 0;
}

.filter_menu li {
    padding: 5px 0px;
    font-size: 12px;
    cursor: pointer;
}

.filter_menu button.dropdown-toggle.btn.btn-primary {
    width: 100%;
    background: transparent;
    border: transparent;
    text-align: left;
    color: #000;
    font-size: 12px;
    font-weight: 400;
    padding: 0;
}

.filter_menu button.dropdown-toggle.btn.btn-primary::after {
    display: none;
}

.filter_menu button.dropdown-toggle.btn.btn-primary:focus {
    box-shadow: unset;
}

.advertiser {
    padding: 20px;
}

.advertiser label {
    font-size: 12px;
    margin-bottom: 10px;
}

.advertiser input {
    margin-bottom: 15px;
}

.advertiser button {
    background: transparent;
    border: transparent;
    font-size: 12px;
}

.filter_menu .dropdown-item {
    font-size: 12px;
    padding: 6px 0px;
    color: #000;
}

.dropdown-menu.show {
    padding: 10px;
}

.filter_menu.dropdown-menu.show {
    min-width: 270px;
    padding: 10px;
}

.advert_tabel table.table {
    background: #fff;
    border: transparent;
    border-radius: 10px;
    border-bottom-right-radius: unset;
    border-bottom-left-radius: unset;

}

.advert_tabel thead {
    background: #ddc6eb;
}

.advert_tabel thead tr th {
    font-weight: bold;
    color: #000;
    font-size: 10px;
    text-align: center;
    padding: 10px;
    white-space: nowrap;
}

.round_select {
    border-radius: 35px;
    padding-left: 5px;

}

.cmn_header_top button.cmn_btn.cmn_bordered_btn {
    background: transparent;
    color: #4759f7;
    border: 1.5px solid #4759f7;
    padding: 7px 40px;
    height: 40px;
}

#dateselect {
    cursor: pointer;
    height: 40px;
}

.owner_filter.mt-0.round_select {
    height: 40px;
}

p.inline {
    display: inline;
}

.date {
    border: unset;
}

/* .date input[type="date"]{
    display: none;
} */
.input[type="date"]:focus {
    outline: 0px !important;
    box-shadow: none;
    -webkit-focus-ring-color: transparent;

}

.select_country_container.round_select label.lbl_position {
    left: 13px;
}

input:focus {
    box-shadow: unset !important;

}

.advert_tabel tr td {
    padding: 15px;
}

.advert_tabel thead th:last-child {
    border-top-right-radius: 10px;
}

.advert_tabel thead th:first-child {
    border-top-left-radius: 10px;
}


.advert_tabel tbody tr:hover {
    background: #F6F7F9;
}

.advert_tabel tbody tr td {
    text-align: left;
}

.mediaplan_details .advert_tabel tr {
    border: unset;
}

.mediaplan_details .advert_tabel thead tr th,
.mediaplan_details .advert_tabel tbody tr td {

    border: unset;
}

.content h2 {
    font-weight: 400;
    font-size: 12px;
}

.filter_menu ul .dropdown button {
    display: flex;
    align-items: center;
}

.filter_menu ul .dropdown button svg {
    margin-left: auto;
}

.filter_menu ul .show.dropdown button svg {
    transform: rotate(90deg);
}

.advert_tabel thead {
    background: #ddc6eb;
    border-bottom: 1px solid #ddc6eb;
}

.filter_menu button.dropdown-toggle.btn.btn-primary.filter_btn {
    padding: 5px 10px;
    display: flex;
    height: 30px;
    font-size: 12px;
    letter-spacing: 0.1px;
    color: #999999;
    border: 1px solid #D0D0D0;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.08);
    border-radius: 5px;
    align-items: center;
}

.advert.dropdown svg {
    fill: #000;
}

.campiagn_filter .dropdown-menu.show {
    position: relative !important;
    transform: unset !important;

}

.media_dropowns {
    display: flex;
    justify-content: flex-end;
}

.media_dropowns .dropdown button {
    border: transparent;
    color: #444444;
    outline: unset;
    height: unset;
    background: #FFFFFF;
    box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.16);
    border-radius: 12px;
    margin-right: 10px;
    padding: 8px;
}

.media_dropowns .dropdown button:focus {
    box-shadow: unset;
    color: #000;
}

.advert_tabel.campaign_showlist button {
    padding: 0;
    font-size: 11px;
    height: unset;
}

.media_dropowns .show>.btn-primary.dropdown-toggle {
    box-shadow: unset;
    /* background: transparent; */
    color: #000;

}

.status_dropdown.dropdown,
.status_element {
    padding: 5px;
    box-shadow: 0px 0px 3px rgba(0, 0, 0, 20%);
    border-radius: 35px;
    max-width: 140px;
}

.status_dropdown.dropdown {
    position: unset;
}

p.mt-2 {
    display: inline;
}

.status_element {
    display: inline-block;
    width: 70px;
}

.icons_downloads {
    display: flex;
    gap: 24px;
}

.status_dropdown.dropdown button {
    background: transparent;
    color: #000;
    border: transparent;
    width: 100%;
    align-items: center;
    gap: 10px;
    display: flex;
}

.advert_tabel thead tr:hover {
    background: unset;
}

.advert_tabel ul,
.advert_tabel {
    position: relative;
}
.advert_tabel ul.optionContainer{
    padding: 0;
    width: 100%;
    background-color: #fff;
}
.advert_tabel ul.optionContainer li{
    padding: 10px;
}

.media_dropowns .dropdown button img {
    width: 30px;
    margin-right: 5px;
}
.advert_tabel ul.optionContainer{
    display: block;
}
.advert_tabel ul {
    display: flex;
    flex-wrap: wrap;
    padding: 16px;
    margin: 0;
    justify-content: space-between;
}

.media_plan_info_list {
    background: #FFFFFF;
    box-shadow: 0px 10px 60px rgba(226, 236, 249, 0.5);
    border-radius: 8px;
}

.title__active {
    display: flex;
    gap: 10px;
    align-items: center;
    margin-bottom: 15px;
}

.title__active h3 {
    margin: 0;
    font-size: 16px;
    font-weight: bold;
    text-transform: capitalize;
}

.status_badge p {
    margin: 0;
    padding: 4px 10px;
    background: #fff;
    color: #0cab4e;
    border-radius: 35px;
    border: solid 1.5px #e9ebf1;
    font-size: 12px;
    font-weight: bold;
}

.status_description_active strong {
    color: #0cab4e;
    padding-left: 4px;
    font-size: 12px;
    font-weight: bold;
}

.status_description_new strong {
    color: #7209b7;
    padding-left: 4px;
    font-size: 12px;
    font-weight: bold;
}

.status_description_inactive strong {
    color: #757e95;
    padding-left: 4px;
    font-size: 12px;
    font-weight: bold;
}

.status_description_active strong {
    color: #0cab4e;
    font-size: 12px;
    font-weight: bold;
}

.advert_tabel ul li p,
.buget_wraper li p {
    font-weight: 600 !important;
    font-size: 12px;
    line-height: 22px;
    color: #000 !important;
    margin-bottom: 0px;
    font-family: 'Montserrat';
}

/* owner select */
li.owner_slect {
    display: flex;
    position: relative;
    background: #f6f7f9;
    padding: 6px;
    padding-top: 20px;
    border-radius: 8px;
    height: 40px;
}

li.owner_slect label {
    position: absolute;
    top: 3px;
    margin: 0;
    padding-left: 5px;
    color: #9f9f9f;
}

li.owner_slect select {
    width: 100%;
    background: transparent;
    border: transparent;
    padding-left: 0px;
}

ul.owners,
ul.budget {
    display: flex;
    gap: 12px;
    align-items: center;
    background: #fff;
    padding: 10px 16px;
    padding-top: 9px;
    border-radius: 7px;
}

ul.budget.single_budget {
    flex-wrap: wrap;
}

ul.budget.single_budget li.progress_result {
    width: 100%;
    flex-wrap: wrap;
}

ul.budget.single_budget li.progress_result p {
    width: 100%;
    justify-content: flex-end;
}

ul.budget.single_budget li.total_used {
    display: flex;
    gap: 20px;
    align-items: center;
}


ul.budget.single_budget {
    padding: 16px;
}

.cmn__filters.without_filter_head ul {
    margin-bottom: 24px;
}

ul.budget {
    padding: 11.9px 16px;
    justify-content: space-between;
}

li.progress_result>div {
    width: 107px;
}

.bar_color.progress {
    background-image: linear-gradient(to bottom, #d561a9 4%, #fdc9e9 100%);
    --bs-progress-bar-bg: transparent;
}

.progress-bar {
    position: relative;
}

.progress-bar::after {
    width: 100%;
    height: 100%;
    position: absolute;
    content: "";
    background-image: linear-gradient(to bottom, #3948ca 6%, #b5bdfc 100%);
    border-bottom-right-radius: 4.5px;
    border-top-right-radius: 4.5px;
}

.budget {
    margin-bottom: 0;
}


ul.budget li {
    justify-content: space-between;
    gap: 10px;
    align-items: center;


}

li.progress_result {
    display: flex;
}

li.progress_result p {
    display: flex;
    gap: 5px;
    margin: 0;
}

ul.budget li:first-child {
    width: auto;
    font-weight: bold;
    font-size: 12px;
}

/* ul.budget li:nth-child(2) {
    flex-grow: 1;
} */

li.total_used p {
    margin: 0;
}

.totalbuget .dot_circle {
    width: 12px;
    height: 12px;
    background-image: linear-gradient(to bottom, #3948ca 6%, #b5bdfc 100%);
    border-radius: 50%;
}

.totalbuget {
    display: flex;
    align-items: center;
    gap: 5px;
}

.totalbuget:last-child .dot_circle {
    background-image: linear-gradient(to bottom, #d561a9 6%, #fdc9e9 100%);
}

ul.owners li {
    width: 100%;
}

ul.owners li:first-child {
    font-weight: bold;
    font-size: 12px;
}

/* owner select */
.advert_tabel .header__media li {
    display: flex;
    align-items: center;
    width: unset;
}

ul.header__media {
    justify-content: space-between;
}

.advert_tabel .header__media li:first-child {
    flex-grow: 1;
}

.advert_tabel ul li {
    padding: 0px 8px;
}

.kite_space_cmn {
    margin-bottom: 40px;
}

.toggle_navgations {
    margin-bottom: 24px;
}

.single_tab .nav-tabs .nav-link.active {
    background: transparent;
    border: transparent;
    font-weight: bold;
    color: #000;
    font-size: 14px;
    padding-bottom: 0;
}

ul.single_tab.nav.nav-tabs {
    border: transparent;
    margin-bottom: 24px;
}

ul.header__media li svg {
    margin-right: 8px;
}

li.block_divider span {
    width: 1px;
    height: 25px;
    background: #ddd;
    display: inline-block;
}

.advert_tabel ul {
    position: relative;
}

.advert_tabel ul li.edit_campaign_details {
    position: absolute;
    right: 13px;
    width: unset;
    bottom: 9px;
}

.advert_tabel ul li.edit_campaign_details svg {
    font-size: 12px;
}

.media_header {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}

.advert_tabel li strong {
    font-weight: 400;
    font-size: 12px;
}

.donut_chart {
    background: #FFFFFF;
    box-shadow: 0px 10px 60px rgba(226, 236, 249, 0.5);
    border-radius: 20px;
    height: 100%;
}

.donut_chart svg {
    height: unset !important;
    width: unset !important;
}

.media_header h2 {
    margin: 0;
}

.newcampaign {
    flex-grow: 1;
    margin-left: 20px;
}

.filter_menu.dropdown-menu.show {
    position: absolute !important;
    top: 50px !important;
    box-shadow: 0 1px 6px rgb(0 0 0 / 20%);
    z-index: 2;
    background: #fff;
    left: 0 !important;
    right: 0 !important;
    bottom: auto !important;
}

/* .filter_menu.dropdown-menu.show {
       max-height: 380px;
      overflow-y: auto; 
  } */

.child_filter.dropdown-menu.show {
    margin-top: 5px;
    padding: 10px;
    margin-bottom: 10px;
}

.search_items {
    overflow: auto;
    min-height: 25px;
    max-height: 250px;
}




li.search {
    position: relative;
    padding: 0px 7px;
    margin: 0px -10px;
    border-bottom: 1px solid #ddd;
    padding-bottom: 8px
}

li.search svg {
    position: absolute;
    right: 15px;
    top: 11px;
}

li.search input {
    padding-right: 30px;
    font-size: 12px;
    height: 40px;
}

span.show_filter {
    background: #10163a;
    padding: 5px 20px;
    border-radius: 35px;
    margin-right: 10px;
    color: #fff;

}

span.show_filter svg {
    margin-left: 7px;
    cursor: pointer;
    font-size: 12px;
}

.filter_menu ul .show.dropdown .multi_filter.dropdown button svg {
    transform: rotate(0deg);
}

.No_Data {
    width: 100%;
    height: 300px
}

.filter_menu ul .show.dropdown .multi_filter.show.dropdown button svg {
    transform: rotate(90deg);
}

.campiagn_filter .owner_filter .owner_options.dropdown-menu.show {
    position: absolute !important;
    top: 45px !important;
}

.owner_filter.dropdown button#dropdown-basic {
    background: transparent;
}

.owner_options button#dropdown-basic {
    padding: 0px;
    min-width: 171px;
}

.filter_menu ul .owner_filter.show.dropdown button svg {
    transform: unset;
}

/* nodata */
.no_data_cmn {
    width: 100%;
    height: 250px;
    position: relative;
}

.no_data,
.notfounwrapper {
    min-height: 280px;
}

.notfounwrapper {
    background-color: #fff;
    box-shadow: 0px 10px 60px rgba(226, 236, 249, 0.5);
    border-radius: 20px;
}

.no_data tbody,
.notfounwrapper {
    position: relative;
}

.no_data img.nodata,
.notfounwrapper img {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 200px;
}

/* Track  */
.child_filters.dropdown-menu.show {
    min-height: 34px;
    max-height: 300px;
    overflow-y: auto;
}

.filter_menu.dropdown-menu.show::-webkit-scrollbar-track,
.child_filters.dropdown-menu.show::-webkit-scrollbar-track {
    background: #fff;
}

/* Handle  */
.filter_menu.dropdown-menu.show::-webkit-scrollbar-thumb,
.child_filters.dropdown-menu.show::-webkit-scrollbar-thumb {
    background: #ddd;
    border-radius: 10px;
    height: 30px;
}

.filter_menu.dropdown-menu.show::-webkit-scrollbar,
.child_filters.dropdown-menu.show::-webkit-scrollbar {
    width: 10px;
}



.save_product button {
    border: transparent;
    background: #00e8c9;
    border-radius: 7px;
    padding: 10px 15px;
    display: flex;
    align-items: center;
    font-size: 12px;
    height: 34px;
    width: 120px;
    text-align: center;
    justify-content: center;
}

.save_product input {
    height: 34px;
    margin-bottom: 10px;
}

.save_product {
    margin-top: 11px;
}

/* creatives css start here */
.creativesimg.pdf {
    position: relative;
}

.creativesimg.pdf svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 58px;
}

.creative_text {
    padding: 10px 7px;
}

.creativesimg.pdf {
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 33%);
}

.creativesimg.pdf img {
    height: 93.98px;
    width: 100%;
    object-fit: cover;
}

/* media plan css  */
.mediaplan__tabs ul.tabs_outer_wrapper.nav-tabs li {
    width: 100%;
}

.mediaplan__tabs ul.tabs_outer_wrapper.nav.nav-tabs {
    display: flex;
    flex-wrap: nowrap;
    margin-bottom: 10px;
    padding-bottom: 10px;
    position: relative;
}

.mediaplan__tabs ul.tabs_outer_wrapper.nav.nav-tabs .mediaplan__tabs .tabs_outer_wrapper .nav-link {
    width: 100%;
}

ul.tabs_outer_wrapper.nav.nav-tabs {
    position: relative;
}

ul.tabs_outer_wrapper.nav.nav-tabs::before {
    position: absolute;
    content: "";
    width: 99%;
    height: 1px;
    background-color: #ddd;
    bottom: 12px;
    padding-left: 10px;
}

ul.bg-white.campion_bg.nav.nav-tabs.tab-lg.tabs_outer_wrapper::before {
    background: unset;
}

.optimize_table .filter_menu button.dropdown-toggle.btn.btn-primary {
    padding: 8px 10px;
    border-radius: 32px;
    border: solid 1.5px #e9ebf1;
    background-color: #fff;
}

.optimize_table select {
    padding: 8px 12px;
    border-radius: 32px;
    border: solid 1.5px #e9ebf1;
    background-color: #fff;
}

/* .optimize_table select:focus{
    outline: unset;;
} */
.optimize_table .dropdown-menu.show a.active__option,
span.active__option {
    color: #0cab4e;
}

.optimize_table .dropdown-menu.show a.active__draft {
    color: #7209b7;
}

.optimize_table .dropdown-menu.show a.active__pause {
    color: #f47839;
}

.optimize_table .dropdown-menu.show a.active__inactiove {
    color: #989fb3
}

.stroke_width {
    strokeWidth: 2;
}
.advert_tabel.history_list ul {
    gap: 10px;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: unset;
}
.history_grid .accordion .accordion-item {
    border: unset;
    border-radius: unset;
    background: unset;
    /* border-bottom: 1px solid #ddd; */
}
.history_grid {
    padding: 10px;
    background: #fff;
    border-radius: 8px;
}

.table-responsive {
    box-shadow: 0px 0px 2px rgba(0,0,0,15%);
    border-radius: 10px;
}
.history_grid .accordion-button:not(.collapsed){
    background-color: unset;
    color: unset;
}
.history_grid h4 {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 10px;
}
.history_grid .accordion .accordion-item button:focus {
    background: unset;
    outline: unset;
    box-shadow: unset;
    border: unset;
}
.history_grid  .accordion-body{
    padding-top: 0px;
}
.history_grid .accordion .accordion-item button, .history_grid .accordion-body{
    padding-left:30px;
    background: unset;
}
.history_grid .accordion-button:not(.collapsed)::after {
    background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path fill="%23000000" fill-rule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"/></svg>');
 }
.history_grid  .accordion-button::after{
    position:absolute;
        left:0
}

.history_grid tbody tr:hover .accordion button {
    background: #F6F7F9;
}
.optimize_table .dropdown-menu.show a,
.font_bold {
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
}

.filter_menu.devices_menu .dropdown-toggle {
    display: flex;
    flex-wrap: wrap;
    position: relative;
    padding-right: 25px;
}


.filter_menu.devices_menu svg {
    position: absolute;
    right: 8px;
}

.optimize_table ul.tabs_outer_wrapper.nav-tabs .nav-link.active::before {
    background: linear-gradient(to right, #793c88 0%, #3948ca 100%);
    box-shadow: unset;
}
svg.changeIcon {
    fill: #4759f7;
}
.mediaplan__tabs ul.tabs_outer_wrapper.nav-tabs .nav-link.active {
    width: 100%;
}
.optimize_table .edit_wrapper span {
    width: unset;
}

.devices_names {
    font-size: 12px;
}
.whiteSpace{
    white-space: nowrap;
}
.optimize_table .edit_wrapper 
 img {
    width: 15px;
}
.optimize_table .table-responsive input {
    min-width: 60px;
}

.optimize_table .pdf_icon {
    width: 25px;
    height: 25px;
line-height: 23px;
}
.optimize_table .filter_menu button.dropdown-toggle.btn.btn-primary span{
    font-weight: 600;
    font-size: 10px;
}
.optimize_table .filter_menu button.dropdown-toggle.btn.btn-primary {
    min-width: unset;
    width: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;

}
.filter_menu .status_dropdown.dropdown {
    box-shadow: unset;
}
/* comn header */
.cmn__filters ul {
    display: flex;
    align-items: center;
    gap: 12px;
    margin-bottom: 40px;
}

.cmn__filters ul li {
    width: 198px;
}

.smallerLi {
    width: 120px !important;
}

.cmn__filters ul li:first-child {
    width: auto;
}
svg.changeIcon {
    fill: #4759f7;
}
.user_email.dropdown-menu {
    max-height: 295px;
    overflow: auto;
}

.cmn_header__search {
    background: #f4f2f9;
    padding: 4px;
    width: 250px;
    display: flex;
    align-items: center;
    border-radius: 35px;
    padding-left: 12px;
    margin: auto;
}

.cmn_header_top h3 {
    font-size: 16px;
    font-weight: bold;
    margin: 0;
    display: flex;
    align-items: center;
    gap: 5px;
}

.cmn_header_top button.cmn_btn,
.modal-body .cmn_btn {
    background: #4759f7;
    padding: 11px 40px;
    border-radius: 35px;
    height: unset;
    box-shadow: unset;
    font-size: 12px;
    font-weight: bold;
}

.cmn_header_top button.cmn_btn {
    height: 40px;
    min-width: 222px;
    padding: unset;
}

.cmn_header_top button.cmn_btn svg {
    margin-right: 13px;
}

.modal-body .cmn_btn {
    min-width: 220px;
}

.cmn_header__search svg {
    margin-right: 0;
}

.cmn_header__search input {
    background: transparent;
    width: 100%;
    border: transparent;
    padding: 8px;
}

.header__media li strong {
    margin-right: 4px;
}

.cmn_header_top {
    background: #fff;
    padding: 24px 16px;
    display: flex;
    align-items: center;
    gap: 24px;
    position: sticky;
    width: 100%;
    top: 71px;
    background: white;
    z-index: 5;
    margin-bottom: 40px;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 14%);
}

.cmn_header_top.campaign__header .cmn_header__search {
    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%);
    top: 50%;
}

.cmn_header_top.campaign__header {
    padding: 35.9px 16px;
}

.cmn__filters.campiagn_filter.filter_menu .dropdown-menu a.dropdown-item {
    white-space: pre-wrap;
    word-wrap: break-word;
    word-break: break-all;
    white-space: normal;
    display: block;
}

.cmn__filters.campiagn_filter.filter_menu .dropdown-menu {
    min-height: 20px;
    max-height: 290px;
    overflow-y: auto;
}

.cmn__filters.campiagn_filter.filter_menu .dropdown-menu::-webkit-scrollbar {
    width: 5px;
}

/* Track */
.cmn__filters.campiagn_filter.filter_menu .dropdown-menu::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
.cmn__filters.campiagn_filter.filter_menu .dropdown-menu::-webkit-scrollbar-thumb {
    background: #888;
}

/* Handle on hover */
.cmn__filters.campiagn_filter.filter_menu .dropdown-menu::-webkit-scrollbar-thumb:hover {
    background: #555;
}

/* comn header */

span.status__optimize {
    display: inline-block;
    align-items: center;
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    padding: 4px 12px 4px 8px;
    border-radius: 32px;
    border: solid 1.5px #e9ebf1;
    background-color: #fff;
}

.footer__dropdown button:last-child {
    padding: 6px 14px 6px;
    border-radius: 100px;
    background-color: #4759f7;
    color: #fff;
}

.footer__dropdown {
    display: flex;
    justify-content: space-between;
}

.footer__dropdown button {
    background: transparent;
    border: transparent;
}
.edit_wrapper span {
    width: 140px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
}
table div.multiSelectContainer input{
    margin: 5px 10px;
}
span.status__optimize.green_active {
    color: #0cab4e;
}

span.status__optimize.purple_draft {
    color: #7209b7;
}

span.status__optimize.orange_pause {
    color: #f47839;
}

span.status__optimize.grey_inactive {
    color: #989fb3;
}

span.info_badge {
    display: inline-block;
    width: 15px;
    height: 15px;
    text-align: center;
    line-height: 15px;
    border-radius: 12px;
    background-color: #dadefd;
}
ul.days_weeks li span {
    display: block;
    text-align: center;
    padding: 1px 0px;
}

ul.days_weeks li span.block_bg span {
    height: 20px;
    width: 10px;
    max-height: 20px;
    background: #00d7c8;
}

.advert_tabel ul.days_weeks {
    flex-wrap: nowrap;
    justify-content: start;
    gap: 0;
}

.advert_tabel ul.days_weeks li {
    padding: 0px 3px;
}

.optimize_table table.table th {
    /* width: 200px; */
    text-align: left;
    white-space: nowrap;
}

/* .optimize_table table {
    table-layout: fixed;
} */
.optimize_table .advert.dropdown{
    position: unset;
}
tr.row_dropdown {
    background: #f6f7f9;
}
span.block_bg {
    height: 20px;
    display: flex !important;
    align-items: end;
    justify-content: center;
}

.mediaplan__tabs .tabs_outer_wrapper .nav-link {
    color: #000;
    font-size: 15px;
    font-weight: bold;
}

.cmn_header__search input:focus {
    outline: unset;
}

.mediaplan__tabs .advert_tabel tbody tr td {
    padding: 10px;
}
.mediaplan__tabs ul.tabs_outer_wrapper.nav-tabs .nav-link.active {
    width: 100%;
    background: transparent;
    line-height: unset;
    padding: 15px;
    color: #000;
    position: relative;
}
.mediaplan__tabs ul.tabs_outer_wrapper.nav-tabs .nav-link.active::after {
    position: absolute;
    content: "";
    background-image: linear-gradient(to right, #793c88 0%, #3948ca 100%);
    width: 100%;
    height: 2px;
    left: 0;
    bottom: 2px;
}

.advert_tabel.optimize_table thead {
    background: #ddc6eb;
}

.total_pages span {
    font-weight: 500;
}

.advert_tabel.campign_index_table .cmn__pagination ul {
    justify-content: flex-end;
    height: 50.8px;
}

.advert_tabel .cmn__pagination ul {
    border-radius: unset;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    padding: 5px;
    justify-content: center;
    gap: 15px;
    align-items: center;
    background-color: #fff;
}

.advert_tabel .cmn__pagination ul li {
    width: auto;
    cursor: pointer;
}

table.table tbody tr {
    border-bottom: 1px solid #e9ebf1;
}
/* optimize */
.optimize_table  .search-wrapper.searchWrapper.singleSelect {
    display: flex;
    justify-content: space-between;
    background-image: url('./../../assets/images/arrow_down.svg');
    background-repeat: no-repeat;
    background-position: 93%;
    background-size: 14px;
}

.optimize_table  .search-wrapper.searchWrapper.singleSelect .icon_down_dir {
    position: unset;
    right: unset;
    top: unset;
    transform: unset;
    margin-left: -25px;
    opacity: 0;
}
.optimize_table .filter_menu.padding-end button.dropdown-toggle.btn.btn-primary {
    padding-right: 25px;
}
.optimize_table #multiselectContainerReact {
    position: unset;
}
.advert_tabel ul li.no__rows {
    min-width: 90px;
    border-radius: 20px;
    background-color: #f6f2f9;
}

.pdf_icon {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background: #f7f3f9;
    text-align: center;
    /* display: flex; */
    line-height: 28px;
    /* margin: auto; */
}

.advert_tabel.campign_index_table tr>td {
    padding: 10px;
}

li.no__rows select {
    width: 100%;
    background: transparent;
    border: transparent;
    padding-top: 8px;
    padding-bottom: 8px;
}

li.no__rows select:focus {
    outline: unset;
}

li.no__rows span {
    position: absolute;
}

/* video card */
.video_view_card {
    padding: 15px;
    background: #fff;
    border-radius: 8px;
    box-shadow: 0px 0px 7px rgba(0, 0, 0, 15%);
    position: relative;
}

.edit_video_card {
    width: 25px;
    height: 25px;
    background: #ddd;
    text-align: center;
    line-height: 25px;
    border-radius: 50%;
    position: absolute;
    right: 6px;
    top: 5px;
}


.creativesimg iframe {
    width: 100%;
    background: #fbe781;
    border-radius: 6px;
    height: 300px;
}

/* status_design*/
.filter_btn span {
    font-weight: bold;
}

.status_design a.dropdown-item {
    padding: 5px 0px;
    font-weight: bold;
}

.status_design a.dropdown-item:hover {
    background-color: transparent;
}

.status_design a.dropdown-item img {
    margin-right: 7px;
}

a.draft.dropdown-item,
.filter_btn.draft span {
    color: #7209b7;
}

a.active.dropdown-item,
.filter_btn.active span,
.filter_btn.on span,
a.on.dropdown-item {
    color: #0cab4e;
}

a.pause.dropdown-item,
.filter_btn.pause span {
    color: #f47839;
}

a.inactive.dropdown-item,
.filter_btn.inactive span,
.filter_btn.off span,
a.off.dropdown-item {
    color: #757e95;
}

div.status_dropdown.media_plan_dropdown.status_design.on_off.dropdown .dropdown-menu.show {
    min-height: 40px !important;
}

.billableDivDropdown {
    background: transparent;
    color: #4759f7;
    border: 1.5px solid #4759f7;
    height: 40px;
    width: 220px;
    border-radius: 35px;
}

.billableDropdown {
    width: 100% !important;
    background: transparent;
    color: #4759f7;
    border: 1.5px solid #4759f7;
    height: 40px;
    width: 220px;
    border-radius: 35px;
}

.whitelistTop {
    margin-top: 10px;
}

.boxLineItemFile {
    background-color: #f6f7f9;
    width: 100%;
    height: 55px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.labelFileWhite {
    padding: 19px 20px !important;
}

.labelFileWhiteButton {
    padding: 0px 0px !important;
    width: 120px !important;
    display: flex;
    justify-content: center;
    align-content: center;
    flex-wrap: wrap;
    margin-top: 0px !important;
    height: 40px;
}

.labelFile {
    padding: 10px 20px;
    border-radius: 35px;
    background-color: #4759f7;
    color: #fff !important;
    cursor: pointer;
    border: 0px;
    font-family: "Montserrat" !important;
    font-size: 12px;
    font-weight: bold !important;
    margin-top: 10px !important;
    margin-right: 15px !important;
}


.labelFileTitle {
    margin-top: 10px !important;
    margin-left: 15px !important;
}

.boxCreativeFile {
    background-color: #f6f7f9;
    width: 100%;
    border-radius: 8px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}

.boxCreativeFileDisable {
    background-color: rgba(var(--bs-secondary-rgb), var(--bs-bg-opacity)) !important;
}


.labelFileCreative{
    font-size: 12px;
    margin-top: 10px;
    margin-left: 10px;
    margin-bottom: 0px !important;
}

.labelChooseFileCreative {
    padding: 7px 11px;
    margin-right: 10px;
    border-radius: 35px;
    background-color: #4759f7;
    color: #fff !important;
    cursor: pointer;
    border: 0px;
    font-family: "Montserrat" !important;
    font-size: 12px;
    font-weight: bold !important;
    margin-bottom: 2px !important;
}

.enableList {
    font-family: "Montserrat" !important;
    font-size: 12px;
}

input[type=file]::file-selector-button {
    background: #4759f7;
    padding: 10px 20px;
    border-radius: 35px;
    color: #fff;
    cursor: pointer;
    border: 0px;
    font-family: "Montserrat" !important;
    font-size: 12px;
    font-weight: bold;
}

.colLineItemTitleList {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
}

.colLineItemTitleListBorderBottom {
    border-bottom: 4px solid #3948CA;
}

.listsTitles {
    display: flex;
    justify-content: space-around;
    align-items: center;
    border-bottom: 1px solid #ccc !important;
    font-family: "Montserrat" !important;
    font-size: 12px;
    font-weight: bold;
    height: 40px;
}

.uploadListButton {
    background: #4759f7;
    padding: 10px 20px;
    border-radius: 35px;
    color: #fff;
    cursor: pointer;
    border: 0px;
    font-family: "Montserrat" !important;
    font-size: 12px;
    font-weight: bold;
    margin-top: 30px;
    margin-left: 30px;
}

.buttonListCampaign {
    all: unset;
}

.lineItemListPadding {
    padding-bottom: 40px;
}

.fileFormatList {
    width: 820px;
    padding: 10px;
    background-color: #E6D9E9;
    margin-top: 30px;
    display: flex;
    border-radius: 6px;
    color: #2F2F35;
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.btnExampleFile {
    background: #4759F7;
    width: 201px;
    height: 40px;
    flex-shrink: 0;
    color: white;
    border-radius: 35px;
    height: unset;
    box-shadow: unset;
    font-size: 12px;
    font-weight: bold;
    border: 0;
}

.divBtnDownloadFile {
    display: flex;
    justify-content: flex-end;
    padding-bottom: 20px;
}

.btnDownloadFileList {
    padding: 12px 25px;
}

.whiteListUpload {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.fileUploadWhiteList {
    background-color: white;
    margin-top: 40px;
    border-radius: 8px;
    width: 600px;
    height: 70px;
    padding: 5px 40px;
    display: flex;
    justify-content: flex-start;
    align-content: center;
}

.fileUploadWhiteListContainer {
    display: flex;
    align-items: center;
    justify-content: center;
}

.labelFileColorWhite {
    color: white !important;
}

input[type=file]::file-selector-button:hover {
    background: #4759f7;
  }

.billableDropdownBilled {
    width: 100% !important;
    background: transparent;
    color: #4759f7;
    border: 1.5px solid #4759f7;
    height: 40px;
    width: 220px;
    border-radius: 35px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
}

.cancelChanges {
	padding: 10px 20px !important;
	margin-left: 20px !important;
}

/* video card */
.owner_slect button.dropdown-toggle.btn.btn-primary {
    background: transparent;
    color: #000;
    border: transparent;
    padding-bottom: 0px;
    width: 100%;
    text-align: left;
    display: flex;
    justify-content: space-between;
    padding-left: 7px;
    padding-right: 0;
    padding-top: 0px;
}

.owner_slect button.dropdown-toggle.btn.btn-primary::after {
    display: none;
}
.position-unset{
    position: unset;
}
.campaign_up_list {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    padding-right: 25px;
    padding: 20px;
}
.edit_wrapper {
    /* border: 1px solid #ddd; */
    /* border-radius: 30px; */
    position: relative;
    /* padding: 10px; */
}.new_compaign.tabs_wrappers .advert_tabel,
.new_compaign.tabs_wrappers .searchWrapper {
    position: unset;
}
/* .new_compaign.tabs_wrappers .multiSelectContainer{
    position: unset !important;
} */
.new_compaign.tabs_wrappers .optionListContainer{
max-width: 300px;
width: 100%;
}
/* .edit_wrapper .pdf_icon {
    position: absolute;
    right: -9px;
    top: -6px;
} */
/* media plan css  */

@media(max-width:1499px) {
    ul.budget li {
        gap: 5px;
    }

    li.progress_result {
        flex-wrap: wrap;
    }

    li.progress_result>div {
        width: 103px;
    }

    .cmn__filters.line_items_filter ul li {
        width: 170px;
    }

    .cmn__filters.line_items_filter ul li:first-child {
        width: auto;
    }
}

@media(max-width:1399px) {
    .cmn__filters.line_items_filter ul li {
        width: 165px;
    }

    .cmn__filters.line_items_filter ul li:first-child {
        width: auto;
    }
}