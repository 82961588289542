/* Button */

.regularButton {
  background-color: #4759f7;
  border: transparent;
  border-radius: 100px;
  color: #fff;
  flex-grow: 0;
  font-weight: 700;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: color .15s ease-in-out;
  --bs-btn-disabled-opacity: 0.65;
  --bs-btn-focus-box-shadow: 0 0 0 0.25rem rgba(var(--bs-btn-focus-shadow-rgb),.5);
}

.standardSizeButton {
  width: 130px !important;
  height: 40px !important;
}

.mediumSizeButton {
  width: 180px !important;
  height: 40px !important;
}

.largeSizeButton {
  width: 230px !important;
  height: 40px !important;
}

.smallSizeButton {
  width: 85px !important;
  height: 35px !important;
}

.smallHeightSizeButton {
  height: 23px !important;
}

.hundredSizeButton {
  width: 100% !important;
  height: 100% !important;
}

.disabledButton {
  background-color: #989fb3 !important;
  cursor: not-allowed !important;
}

/* Searcher */

.regularSearchDiv {
  display: flex;
  align-items: center;
  background-color: white;
  border-radius: 9px;
  height: 35px;
  width: 100%;
  padding-left: 10px;
  padding-right: 10px;
}

.regularSearchIcon {
  margin-top: 3px;
}

.regularSearchInput {
  width: 80%;
  padding-left: 10px;
  font-size: 15px;
  border: none !important;
}
  
.regularSearchInput:focus {
  outline: none !important;
}

.searchDivGrey {
  background-color: #f6f7f9;;
}

.searchInputGrey {
  background-color: #f6f7f9;;
}

.cancelSearch {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 20%;
}