@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700;800&display=swap');


body,
h1,
h2,
h3,
h4,
h5,
h6,
li,
p,
span,
button,
strong {
  font-family: "Montserrat" !important;
}

table tr td,
table p,
table a,
table strong,
table {
  font-family: "Montserrat" !important;
}

h3 {
  font-size: 12px;
}
.fixed_tale{
  table-layout: fixed;
}

.pagination {
  margin-top: 3px;
}

.table-responsive th,
.table-responsive td {
  vertical-align: middle;
}
.header_center{
  display: flex;
  gap: 10px;
  white-space: nowrap;
}
.modal-header {
  background: #fff;
  border-bottom: unset;
  padding: 24px;
}

button.clear__btn.purple_bg {
  background: #ddc6eb;
  color: #000;
}

.modal-title {
  font-weight: bold;
  color: #000;
  font-size: 12px;
}

.modal-body {
  background: #fff;
}

.modal-body label {
  color: #000000;
  font-weight: 500;
}

._border {
  border-top: 2px solid #CFCFCF;
  border-bottom: 2px solid #CFCFCF;
  margin-bottom: 20px;
}

.level_spacing {
  margin-left: -15px;
  margin-right: -15px;
  padding: 15px;
}

div.content .cmn_title {
  font-weight: bold;
  font-size: 12px;
  line-height: 24px;
  color: #000000;
  margin-bottom: 24px;

}

.forgot_password {
  color: #878787;
  text-decoration: none;
  cursor: pointer;
}

.react-datepicker {
  font-family: "Montserrat" !important;
}

.react-datepicker-popper {
  z-index: 9999 !important;
}

.zIndexUp {
  z-index: 998 !important;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

ul {
  padding: 0;
}

li {
  list-style: none;
}

a {
  cursor: pointer;
}

.content_outer {
  padding-top: 71px;
  position: relative;
}
.table-responsive input {
  border: 1px solid #ddd;
}
a.page-link {
  color: #333333;
  font-weight: 600;
}

.content_outer {
  margin-left: 280px;
  background: #f1f2f7;
  min-height: 100vh;
}

/* .rmsc.mutiselect_cmn {
  width: 70%;
} */
.cmn_pagination {
  text-align: right;
  margin-top: 10px;
}

.settings_btns button {
  height: 40px;
}

.settings_btns {
  justify-content: end;
}

ul.pagination {
  justify-content: end;
  gap: 8px;
}

.cmn_pagination svg {
  font-size: 17px;
  filter: #000;
}

ul.pagination li a {
  background: #FFFFFF;
  width: 32px;
  text-align: center;
  height: 32px;
  padding: 0;
  line-height: 32px;
  border-radius: 8px !important;
  border: unset;
}



li.page-item.active a {
  background: #10163A;
  border: unset;
}

div.rmsc .dropdown-container {
  border: transparent;
  border-radius: 12px;
}

.rmsc.mutiselect_cmn span.gray {
  color: #000;
  font-weight: 500;
}

.rmsc.mutiselect_cmn .dropdown-heading {
  height: 40px;
  color: #000;
  background: #FFFFFF;
  box-shadow: 0px 10px 60px rgba(226, 236, 249, 6.5);
  border-radius: 12px;
  border: unset;
}

.advert_tabel table td {
  font-size: 11px;
}

.advert_tabel thead  th {
  font-size: 11px;
}

button.cmn_btn.cancel_cm_btn {
  background: #CC4444;
  color: #fff;
}

span.create_product {
  color: #000;
  font-weight: 500;
  position: relative;
  top: -2px;
}

button.cmn_btn.delete_cmn_btn {
  background: #ff79c9;
  color: #fff;
}

/* media planner */
.d-flex.settings_btns button {
  height: 40px;
}

/* media planner */
/* card css starts here */

.content {
  padding: 1.875em;
  width: 100%;
}

.card_outer {
  margin-bottom: 1.875em;
  border-radius: 5px;
  padding: 0;
  border: 0px solid transparent;
  -webkit-box-shadow: 0 0 7px rgb(0 0 0 / 8%);
  box-shadow: 0 0 7px rgb(0 0 0 / 8%);
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border-radius: 8px;
  padding: 20px;
}

.card_outer h2 {
  font-size: 12px;
}

.card_outer h4 {
  font-size: 12px;
  font-weight: 400;
  color: #455a64;
}

.metrics ul {
  display: flex;
  flex-wrap: wrap;
}

.metrics>ul>li {
  flex: 0 0 31%;
  margin-bottom: 15px;
}

.reports_inner {
  padding: 32px;
  padding: 22px 40px;
  margin: 8px 30px;
  box-shadow: 0 5px 20px 0 rgb(0 0 0 / 5%);
}

.card_outer h4 {
  color: #455a64;
  font-size: 12px;
  font-weight: 400;
}

.card_outer p {
  color: #99abb4;
  font-size: 12px;
}

.countries_list {
  display: flex;
  flex-wrap: wrap;
}

.countries_list p {
  margin-bottom: 4px;
}

.form-select,
.form-control {
  font-size: 12px;
  height: 30px;
  font-weight: 500;
}

.select_gender label {
  display: flex;
  align-items: center;
  gap: 10px;
}
.select_country_container.cmn_modal_field textarea.form-control {
  background: #f6f7f9;
}
.select_gender div {
  display: flex;
  gap: 10px;
}
.owner_filter.dropdown{
  height: 40px;
}
.form-select,
.form-control,
div.searchWrapper,
.owner_filter {
  font-size: 12px;
  height: 40px;
  font-weight: 500;
  background-color: #FFFFFF;
  box-shadow: 0px 10px 60px rgba(226, 236, 249, 0.5);
  border-radius: 35px;
  border: transparent;
}

.advert_tabel .table-responsive::-webkit-scrollbar {
  width: 4px;
    height:4px
}

/* Track */
.advert_tabel .table-responsive::-webkit-scrollbar-track {
  background: #f1f1f1; 
}
 
/* Handle */
.advert_tabel .table-responsive::-webkit-scrollbar-thumb {
  background: #bdbdbd; 
}

/* Handle on hover */
.advert_tabel .table-responsive::-webkit-scrollbar-thumb:hover {
  background: #555; 
}
div.searchWrapper {
  height: inherit;
}

.modal-body div.searchWrapper {
  height: inherit;
  padding: 0px;
  background: transparent;
  padding-left: 8px;
  padding-bottom: 3px;
}

body.body_toggle .content_outer {
  margin-left: 70px;
}

/* card css ends here */
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  color: white;
}

.App-link {
  color: #61dafb;
}

.content_outer .App {
  text-align: left;
}

.orderSvg {
  width: 16px;
  height: 16px;
  fill: none;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.form_inner {
  position: relative;
}

.loader_outer {
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  text-align: center;
  background: #00000080;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 7px;
  z-index: 999;
}

.loader_outer img {
  width: 180px;
}

.loader {
  border: 6px solid #f3f3f3;
  border-radius: 50%;
  border-top: 6px solid blue;
  width: 80px;
  height: 80px;
  animation: spin 2s linear infinite;
}

body a.cmn_btn.dropdown-item {
  display: inline-block;
  width: unset;
  padding: 0px 20px;
  line-height: 40px;
}

.modal-body {
  padding: 0;
}

input[type="file"] {
  padding: 12px 15px;
}

.modal-header .btn-close {
  color: #000;
  opacity: 1;
}

.word_break {
  word-break: break-all;
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/* media query starts here */
@media(max-width:991px) {}

.page-item.active .page-link {
  z-index: 1;
}

.negative_spacing label {
  margin-bottom: 10px;
}

.negative_spacing {
  margin-left: -15px;
  margin-right: -15px;
  padding: 0px 15px;
  border-bottom: 2px solid rgba(207, 207, 207, 0.37);
  padding-bottom: 10px;
}

.header_nav {
  display: flex;
  align-items: center;
  border-bottom: 2px solid rgba(207, 207, 207, 0.37);
  margin-bottom: 15px;
  margin-left: -15px;
  margin-right: -15px;
  padding: 0px 10px;
  padding-bottom: 10px;
}

.header_nav p::before {
  background: linear-gradient(90.07deg, #3F62DB 0.06%, #FA78C9 99.28%);
  box-shadow: 0px 1px 6px #F177CA;
  border-radius: 10px;
  content: "";
  position: absolute;
  width: 100%;
  height: 3px;
  left: 0;
  right: 0;
  bottom: -14px;
}

.header_nav p {
  margin: 0;
  padding: 0px 24px;
  position: relative;
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  color: #000;
  margin-left: 20px;
}

.header_nav button {
  border: transparent;
  padding: 0;
  font-weight: 400;
  font-size: 12px;
  color: #000;
  background: #FFFFFF;
  box-shadow: 0px 1px 7px rgba(0, 0, 0, 0.12);
  border-radius: 7px;
  width: 34px;
  height: 34px;
  text-align: center;
  line-height: 34px;
}



header.header_outer {
  display: flex;
  align-items: center;
  padding-left: 300px;
}

.header_nav button svg {
  position: relative;
  top: -1px;
}

.status_dropdown.show.dropdown button {
  background: transparent;
  color: #000;
  border: transparent;
}



.status_dropdown.dropdown button {
  background: transparent;
  border: transparent;
  color: #000;
}

.status_dropdown.show.dropdown button:focus {
  box-shadow: unset;
}

.status_dropdown.dropdown button:focus {
  box-shadow: unset;
}

.status_dropdown.dropdown button::after {
  display: none;
}

/* model css */
.model_btn {
  text-align: right;
  display: flex;
  justify-content: flex-end;
  /* padding-right: 20px; */
}

.model_btn {
  gap: 18px;
}

button.cmn_btn.cancel_cmn {
  background: #a9a6a6;
}

.model_content p {
  font-size: 12px;
  text-align: center;
  margin-bottom: 15px;
}

.model_content {
  padding: 15px;
}

.model_content_padding {
  padding-top: 14px;
}

.mediaplan_details label {
  margin-bottom: 10px;
  color: #000;
  font-weight: 500;
}

button.cmn_btn.bg_purple {
  background: purple;
  color: #fff;
}

/* slider css starts  here  */
div.multi-range-slider .bar-left,
div.multi-range-slider .bar-right {
  box-shadow: unset;
  background: #ececee;
}


div.multi-range-slider .thumb::before {
  width: 14px;
  border-radius: 10px;
  box-shadow: unset;
  border-color: #fff;
  background: #176bfb;
  height: 25px;
  bottom: 1px;
}

div.multi-range-slider .bar-inner {
  background: #176bfb;
  box-shadow: unset;
  border: transparent;
}

div.multi-range-slider {
  background: transparent;
  box-shadow: unset;
  border: transparent;
}

div.multi-range-slider .thumb .caption * {
  background: transparent;
  color: #000;
  box-shadow: unset;
  font-size: 12px;
  position: unset;
  min-width: unset;
  height: unset;
}

div.multi-range-slider .caption {
  display: block;
  height: unset;
  bottom: 13px;
  left: -20px;
  width: 28px;
  text-align: center;
}

/* COntext */
.contextContainer {
  z-index: 1;
  width: 100%;
  height: 500px;
  background: #673ab7;
}

.rightClick {
  z-index: 12;
  position: fixed;
  background: rgb(240, 200, 1);
}

.menuElement {
  color: #222222;
  cursor: pointer;
  padding: 17px 36px;
}

.menuElement:hover {
  color: #fff;
  background: #009688;
}

/* media plan css  */
.status_dropdown.media_plan_dropdown.show.dropdownc .dropdown-menu {
  min-height: 135px;
}

.status_dropdown.media_plan_dropdown.show.dropdown .dropdown-menu.show {
  inset: unset !important;
  transform: unset !important;
  right: unset !important;
  bottom: unset ! important;

  margin-top: 10px;
}

.advert_tabel.campaign_showlist tbody tr:first-child .status_dropdown.media_plan_dropdown.show.dropdown .dropdown-menu.show {
  bottom: unset !important;
}

.create_product {
  margin-left: 6px;
}

/* modal common css */
.form-group.select_country_container.cmn_modal_field input,
.form-group.select_country_container.cmn_modal_field select {
  height: 30px;
  background-color: #f6f7f9;
  box-shadow: unset;
}

.cmn_modal_field select:focus {
  box-shadow: unset;
}

.form-group.select_country_container.cmn_modal_field {
  background: #f6f7f9;
  border-radius: 8px;
  min-height: 53px;
}

.form-group.select_country_container.cmn_modal_field .search-wrapper.searchWrapper input {
  /* height: unset; */
  padding-top: 0;
}

.form-group.select_country_container.cmn_modal_field label {
  color: #585f74;
  /* z-index: 2; */
}

.modal-body .select_country_container {
  margin-top: 0px;
}

.rates_list {
  display: flex;
  justify-content: space-between;
}

.rates_list label,
.rates_list span {
  font-weight: normal;
}

.rates_list {
  padding-left: 2px;
}

/* modal common css */